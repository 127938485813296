export type ProductShortName =
'POS' |
'EDC' |
'ATG' |
'AK' |
'OP' |
'ATO' |
'FPS' |
'RAL'

export const sortedProductShortName: ProductShortName[] = [
  'POS',
  'EDC',
  'ATG',
  'AK',
  'OP',
  'ATO',
  'FPS',
  'RAL'
]
