import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestState } from '../common'
import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { UpgradeMonitoringState } from './state'
import { deviceUpgradeMonitoring, siteMonitoringPackageVersions, siteUpgradeMonitoring } from './reducers'
import { SelectedSite } from '../../models/upgradeMonitoring/upgradeMonitoring'
import { sortedProductShortName } from '../../common/constants/supportedProducts'
import { ProductVersion } from '../../models/upgradeMonitoring/packageVersions'

const initialState: UpgradeMonitoringState = {
  siteUpgradeMonitoringState: 'idle',
  sites: [],
  breadcrumbs: [],
  selectedSite: undefined,
  siteMonitoringPackageVersionsState: 'idle',
  packageVersions: undefined,
  deviceUpgradeMonitoringState: 'idle',
  devices: []
}

export const upgradeMonitoringSlice = createSlice({
  name: 'upgradeMonitoring',
  initialState,
  reducers: {
    setSiteUpgradeMonitoringState: (
      state,
      action: PayloadAction<RequestState>
    ) => {
      state.siteUpgradeMonitoringState = action.payload
    },
    setUpgradeMonitoringBreadcrumbs: (state, action: PayloadAction<BreadcrumbSegment[]>) => {
      state.breadcrumbs = [...action.payload].sort((a, b) => a.order - b.order)
    },
    setSelectedSite: (state, action: PayloadAction<SelectedSite | undefined>) => {
      state.selectedSite = action.payload
    },
    setRemovePackageVersions: (state, action: PayloadAction<undefined>) => {
      state.packageVersions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(siteUpgradeMonitoring.pending, (state) => {
      state.siteUpgradeMonitoringState = 'pending'
    })
    builder.addCase(siteUpgradeMonitoring.rejected, (state) => {
      state.siteUpgradeMonitoringState = 'rejected'
    })
    builder.addCase(siteUpgradeMonitoring.fulfilled, (state, action) => {
      state.sites = action.payload
      state.siteUpgradeMonitoringState = 'fulfilled'
    })
    builder.addCase(siteMonitoringPackageVersions.pending, (state) => {
      state.siteMonitoringPackageVersionsState = 'pending'
    })
    builder.addCase(siteMonitoringPackageVersions.rejected, (state) => {
      // * Note: Set the request state to pending so the site monitoring drawer table continues to spin for the new request.
      state.siteMonitoringPackageVersionsState = 'pending'
    })
    builder.addCase(siteMonitoringPackageVersions.fulfilled, (state, action) => {
      const { packageVersions } = action.payload
      const sorted = sortedProductShortName.map((p) => packageVersions?.find((v) => v.product === p)).filter((p): p is ProductVersion => !!p)
      state.packageVersions = {
        ...action.payload,
        packageVersions: sorted
      }
      state.siteMonitoringPackageVersionsState = 'fulfilled'
    })
    builder.addCase(deviceUpgradeMonitoring.pending, (state) => {
      state.deviceUpgradeMonitoringState = 'pending'
    })
    builder.addCase(deviceUpgradeMonitoring.rejected, (state) => {
      state.deviceUpgradeMonitoringState = 'rejected'
    })
    builder.addCase(deviceUpgradeMonitoring.fulfilled, (state, action) => {
      state.devices = action.payload
      state.deviceUpgradeMonitoringState = 'fulfilled'
    })
  }
})

export default upgradeMonitoringSlice.reducer
export const {
  setSiteUpgradeMonitoringState,
  setUpgradeMonitoringBreadcrumbs,
  setSelectedSite,
  setRemovePackageVersions
} = upgradeMonitoringSlice.actions
