import React, { useEffect } from 'react'
import {
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import axios from 'axios'
import { connect } from 'react-redux'
import { BasicDrawer, DrawerHeaderOptions } from '../../../components/Drawer'
import { AppThunkDispatch, RootState } from '../../../redux'
import SiteReadinessDeviceSummary from './siteReadinessDeviceSummary'
import { SuccessState } from '../../../data/siteReadinessData'
import { setSelectedDeviceInfo, getDeviceInfo } from '../../../redux/siteReadiness/slice'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSelectedDeviceInfo: (...args: Parameters<typeof setSelectedDeviceInfo>) => dispatch(setSelectedDeviceInfo(...args)),
    dispatchGetDeviceInfo: (...args: Parameters<typeof getDeviceInfo>) => dispatch(getDeviceInfo(...args))
  }
}
export interface OwnProps {
  openDrawer: boolean
  closeDrawer: () => void
  drawerHeader?: DrawerHeaderOptions
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    selectedSite: state.siteReadiness.selectedSite,
    selectedDevice: state.siteReadiness.selectedDevice,
    deviceInfo: state.siteReadiness.deviceInfo,
    deviceInfoRequestState: state.siteReadiness.deviceInfoRequestState
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type DeviceDrawerProps = OwnProps & StateProps & DispatchProps

function getColor(status: string) {
  switch (status) {
    case SuccessState.Warning:
      return 'orange'
    case SuccessState.Failure:
      return 'red'
    default:
      return 'black'
  }
}

export function SiteReadinessDevicesDrawer({
  user,
  selectedSite,
  openDrawer,
  closeDrawer,
  drawerHeader,
  deviceInfo,
  deviceInfoRequestState,
  dispatchSetSelectedDeviceInfo,
  dispatchGetDeviceInfo,
  selectedDevice
}: DeviceDrawerProps) {
  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    if (
      user.dataCenter
      && user.userId
      && user.sessionId
      && selectedSite
      && selectedDevice
    ) {
      dispatchSetSelectedDeviceInfo(null)
      const { userId, sessionId, dataCenter } = user
      dispatchGetDeviceInfo({
        userId, sessionId, dataCenter, siteId: selectedSite.siteId, deviceId: selectedDevice.deviceId, abortSignal: cancelToken.token
      })
    }

    return () => {
      cancelToken.cancel()
    }
  }, [selectedDevice?.deviceId])

  if (deviceInfoRequestState === 'pending') {
    return (
      <BasicDrawer
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        drawerHeader={drawerHeader}
      >
        <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
          <Typography>Loading...</Typography>
        </Stack>
      </BasicDrawer>
    )
  }

  if (deviceInfo?.deviceDetail == null || deviceInfo?.deviceDetailSummary == null) {
    return (
      <BasicDrawer
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        drawerHeader={drawerHeader}
      >
        <Typography />
      </BasicDrawer>
    )
  }

  const { deviceDetail, deviceDetailSummary } = deviceInfo

  return (
    <BasicDrawer
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      drawerHeader={drawerHeader}
    >
      <Stack spacing={2} paddingLeft={2} paddingTop={2} paddingRight={2} paddingBottom={2}>
        <Stack>
          <Typography variant="h6">Status</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
            Any text in
            <span style={{ color: 'orange' }}> ORANGE </span>
            denotes a warning, but does not prevent readiness.
          </Typography>
          <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
            Any text in
            <span style={{ color: 'red' }}> RED </span>
            denotes a failure
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            Device Type
          </Typography>
          <Typography variant="body1">
            {deviceDetail.deviceType}
          </Typography>
        </Stack>
        <SiteReadinessDeviceSummary />
        <Divider />
        <Stack>
          <Typography variant="h6">
            Upgrade Agent
          </Typography>
          <Stack paddingTop={1}>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Version
            </Typography>
            <Typography variant="body1" color={getColor(deviceDetailSummary?.uaVersion)}>
              {deviceDetail.uaVersion}
            </Typography>
          </Stack>
          <Stack paddingTop={1}>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Status
            </Typography>
            <Typography variant="body1" color={getColor(deviceDetailSummary?.uaStatus)}>
              {deviceDetail.uaStatus}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Typography variant="h6">
            Current Versions
          </Typography>
          <Stack paddingTop={1}>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Version
            </Typography>
            {deviceDetail.currentVersions?.map((versions) => (
              <Typography variant="body1" paddingLeft={1} key={versions.productName}>
                {`• ${versions.productName} (${versions.productVersion}) `}
              </Typography>
            ))}
          </Stack>
          <Stack paddingTop={2}>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              RAL Status
            </Typography>
            <Typography variant="body1" color={getColor(deviceDetailSummary?.ralStatus)}>
              {deviceDetail.ralStatus}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Typography variant="h6">
            Software
          </Typography>
          <Grid container spacing={2} paddingTop={1}>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  OS name
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.osName)}>
                  {deviceDetail.osName}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  OS version
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.osVersion)}>
                  {deviceDetail.osVersion}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  OS Bitness
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.osBitness)}>
                  {deviceDetail.osBitness}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  SQL Version
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.sqlVersion)}>
                  {deviceDetail.sqlVersion}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  SQL Bitness
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.sqlBitness)}>
                  {deviceDetail.sqlBitness}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  .NET Version
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.dotNetVersion)}>
                  {deviceDetail.dotNetVersion}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Vcredist2012
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.vcRedist2012Version)}>
                  {deviceDetail.vcRedist2012Version}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Vcredist2013
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.vcRedist2013Version)}>
                  {deviceDetail.vcRedist2013Version}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Vcredist2019
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.vcRedist2019Version)}>
                  {deviceDetail.vcRedist2019Version}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Divider />
        <Stack>
          <Typography variant="h6">
            Hardware
          </Typography>
          <Grid container spacing={2} paddingTop={1}>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  LOCALDIR Free Space(GB)
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.localDirSpace)}>
                  {deviceDetail.localDirSpace}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  IBERDIR Free Space(GB)
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.iberDirSpace)}>
                  {deviceDetail.iberDirSpace}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  RAM (GB)
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary?.ram)}>
                  {deviceDetail.ram}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  CPU Model
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary.cpuModel)}>
                  {deviceDetail.cpuModel}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  CPU Speed (GHz)
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary.cpuSpeed)}>
                  {deviceDetail.cpuSpeed}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  CPU Cores
                </Typography>
                <Typography variant="body1" color={getColor(deviceDetailSummary.cpuCores)}>
                  {deviceDetail.cpuCores}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </BasicDrawer>
  )
}

SiteReadinessDevicesDrawer.defaultProps = {
  drawerHeader: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteReadinessDevicesDrawer)
