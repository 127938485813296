import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AppThunkDispatch, RootState } from '../../../redux'
import { Table } from '../../../components/Table'
import { SiteDeviceData } from '../../../data/siteReadinessData'
import { siteReadinessDevices, setSiteDevicesRequestState } from '../../../redux/siteReadiness/slice'
import { GreenSuccess, RedError } from '../../../common/constants/colorConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetDevices: (...args: Parameters<typeof siteReadinessDevices>) => dispatch(siteReadinessDevices(...args)),
    dispatchSetSiteDeviceRequestState: (...args: Parameters<typeof setSiteDevicesRequestState>) => dispatch(setSiteDevicesRequestState(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    selectedSite: state.siteReadiness.selectedSite,
    devices: state.siteReadiness.devices,
    devicesRequestState: state.siteReadiness.siteDevicesRequestState
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type DrawerDeviceReadinessTableProps = StateProps & DispatchProps

const checkIcon = <CheckCircleIcon sx={{ color: GreenSuccess }} />// color="success" />
const cancelIcon = <CancelIcon sx={{ color: RedError }} />// color="error" />

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Device ID',
    resizable: false,
    flex: 1,
    hide: true
  },
  {
    field: 'deviceName',
    headerName: 'Device',
    resizable: false,
    flex: 1
  },
  {
    field: 'isPrereqReady',
    headerName: 'Prereqs',
    resizable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (params.row.isPrereqReady ? checkIcon : cancelIcon)
  },
  {
    field: 'isUpgradeReady',
    headerName: 'Ready',
    resizable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (params.row.isUpgradeReady ? checkIcon : cancelIcon)
  }
]

export function DrawerDeviceReadinessTable({
  devicesRequestState,
  user,
  dispatchGetDevices,
  devices,
  selectedSite,
  dispatchSetSiteDeviceRequestState
}: DrawerDeviceReadinessTableProps) {
  const navigate = useNavigate()
  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    if (
      user.dataCenter
      && user.userId
      && user.sessionId
      && selectedSite
    ) {
      dispatchSetSiteDeviceRequestState('pending')
      const { userId, sessionId, dataCenter } = user
      dispatchGetDevices({
        userId, sessionId, dataCenter, siteId: selectedSite.siteId, abortSignal: cancelToken.token
      })
    }

    return () => {
      cancelToken.cancel()
    }
  }, [selectedSite?.siteId])

  const rows = devices.map((d: SiteDeviceData) => ({
    id: d.deviceId,
    deviceName: d.deviceName,
    isPrereqReady: d.isPrereqReady,
    isUpgradeReady: d.isUpgradeReady
  }))

  return (
    <Table
      width="100%"
      rows={devicesRequestState === 'pending' ? [] : rows}
      columns={columns}
      footer={devicesRequestState === 'fulfilled'}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 15]}
      loading={devicesRequestState === 'pending'}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'device', sort: 'asc' }]
        }
      }}
      onSeeAllClick={() => { navigate('/readiness/devices') }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerDeviceReadinessTable)
