import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { AppThunkDispatch, RootState } from '../../redux'
import SiteReadinessTable from './components/siteReadinessTable'
import SiteReadinessDrawer from './components/siteReadinessDrawer'
import {
  setMissingDevices, setSelectedSite, setSiteReadinessBreadcrumbs, setSiteReadinessDevices
} from '../../redux/siteReadiness/slice' // Redux action to set selected site
import { DrawerHeaderOptions } from '../../components/Drawer'
import BreadcrumbNavigation, { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { DefaultDrawerWidth } from '../../common/constants/sizeConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSelectedSite: (...args: Parameters<typeof setSelectedSite>) => dispatch(setSelectedSite(...args)),
    dispatchSetSiteReadinessDevices: (...args: Parameters<typeof setSiteReadinessDevices>) => dispatch(setSiteReadinessDevices(...args)),
    dispatchSetMissingDevices: (...args: Parameters<typeof setMissingDevices>) => dispatch(setMissingDevices(...args)),
    dispatchSetSiteReadinessBreadcrumbs: (...args: Parameters<typeof setSiteReadinessBreadcrumbs>) => dispatch(setSiteReadinessBreadcrumbs(...args))

  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    selectedSite: state.siteReadiness.selectedSite,
    breadcrumbs: state.siteReadiness.breadcrumbs
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type SiteReadinessProps = StateProps & DispatchProps;

export function SiteReadiness({
  user,
  selectedSite,
  dispatchSetSelectedSite,
  breadcrumbs,
  dispatchSetMissingDevices,
  dispatchSetSiteReadinessBreadcrumbs,
  dispatchSetSiteReadinessDevices: dispatchSetDevices
}: SiteReadinessProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [breadcrumbDepth] = useState<number>(1)

  const currentSegment: BreadcrumbSegment = {
    name: 'Site Readiness',
    path: '/readiness',
    order: breadcrumbDepth,
    testId: 'SiteReadinessBreadcrumb'
  }
  // * Reset the state for the Site Readiness page
  useEffect(() => {
    dispatchSetDevices([])
    dispatchSetSelectedSite(null)
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetSiteReadinessBreadcrumbs(segments) }
    )
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>

  const drawerHeaderOptions: DrawerHeaderOptions = {
    enableCloseArrow: true,
    buttons: [
      {
        label: 'More',
        onClick: () => {
          // TODO: Bring to device screen
        },
        variant: 'outlined'
      }
    ]
  }

  const handleRowClick = (site: GridRowParams) => {
    const {
      id,
      siteName,
      currentVersions,
      isPrereqReady,
      isUpgradeReady
    } = site.row
    const row = {
      siteId: id,
      siteName,
      currentVersions,
      isPrereqReady,
      isUpgradeReady
    }
    dispatchSetSelectedSite(row)
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    dispatchSetSelectedSite(null)
    dispatchSetDevices([])
    dispatchSetMissingDevices(null)
    setDrawerOpen(false)
  }

  return (
    <Stack
      alignItems="baseline"
      spacing={4}
      sx={{
        width:
          drawerOpen ? `calc(100% - ${DefaultDrawerWidth}px)` : '100%'
      }}
    >
      <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
      <Typography variant="h4" data-testid="SiteReadinessHeader">
        Site Readiness
      </Typography>
      <Typography variant="body1">
        This is a list displaying readiness for all sites and devices within the selected CMC company
      </Typography>
      <SiteReadinessTable onRowClick={(e) => handleRowClick(e)} />
      {selectedSite && (
        <SiteReadinessDrawer
          openDrawer={drawerOpen}
          closeDrawer={handleDrawerClose}
          drawerHeader={drawerHeaderOptions}
        />
      )}
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteReadiness)
