// ! Temporarily disable the eslint rule (ECS-5373)
// ! Note: Remove these (!) comments when we reintroduce the pages
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { Outlet } from 'react-router-dom'
import SolutionSetsPage from './pages/SolutionSetsPages'
import EditSolutionSet from './pages/SolutionSetsPages/components/EditSolutionSet'
import DeploymentPage from './pages/DeploymentPage'
import { UserState } from './redux/users/reducers'
import SiteTagsPage from './pages/SiteTagsPage'
import SchedulePage from './pages/SchedulePages'
import SiteReadiness from './pages/SiteReadiness'
import SiteReadinessDevices from './pages/SiteReadinessDevices'
import UpgradeMonitoring from './pages/UpgradeMonitoring'
import UpgradeMonitoringDevices from './pages/UpgradeMonitoringDevices'

/**
 * Defines all the necessary information needed for
 * defining a route
 */
export interface Route {
  /** The name of this route */
  title: string;
  /**
   * The absolute URL path of this route for top level routes.
   * Children routes are to be relative to the route it is nested under.
   */
  path: string;
  /** An icon to display on the Sidebar Navigation Component */
  icon?: JSX.Element;
  /** The page element to display when you navigate to this route */
  pageElement: JSX.Element;
  /**
   * A function that defines which users are allowed to see this route on the sidebar
   * Only applicable to top level routes, will not affect children routes.
   *
   * If this is not set, then it will not show up in the sidebar
   */
  shouldShow?: (userRole: UserState) => boolean;
  /**
   * Routes that are to be nested under this route.
   *
   * Children routes will be rendered as an additional component
   * through the <Outlet /> component defined in the route the child
   * is nested under.
   */
  children?: Route[];
}

export const routes: Route[] = [
  // ! Temporarily commenting out all the tabs except for Site Readiness (ECS-5373)
  // ! Note: Remove these (!) comments when we reintroduce the pages
  // {
  //   title: 'Deployments',
  //   path: '/deployments',
  //   icon: <LocalShippingIcon />,
  //   pageElement: <DeploymentPage />,
  //   shouldShow: (userRole: UserState) => userRole.isAdmin
  // },
  // {
  //   title: 'Solution Sets',
  //   path: '/solutionsets',
  //   icon: <GroupWorkIcon />,
  //   pageElement: <Outlet />,
  //   shouldShow: (userRole: UserState) => userRole.isAdmin,
  //   children: [
  //     {
  //       title: 'Solution Sets',
  //       path: '',
  //       pageElement: <SolutionSetsPage />
  //     },
  //     {
  //       title: 'Edit Solution Set',
  //       path: 'edit',
  //       pageElement: <EditSolutionSet />
  //     }
  //   ]
  // },
  // {
  //   title: 'Site Tags',
  //   path: '/sitetags',
  //   icon: <LocalOfferIcon />,
  //   pageElement: <SiteTagsPage />,
  //   shouldShow: (userRole: UserState) => userRole.isAdmin
  // },
  // {
  //   title: 'Schedule',
  //   path: '/schedule',
  //   pageElement: <SchedulePage />
  // },
  {
    title: 'Site Readiness',
    path: '/readiness',
    pageElement: <Outlet />,
    shouldShow: (userRole: UserState) => userRole.isAdmin,
    children: [
      {
        title: 'Site Readiness',
        path: '',
        pageElement: <SiteReadiness />
      },
      {
        title: 'Site Readiness Devices',
        path: 'devices',
        pageElement: <SiteReadinessDevices />
      }
    ]
  },
  {
    title: 'Monitoring',
    path: '/monitoring',
    pageElement: <Outlet />,
    shouldShow: (userRole: UserState) => userRole.isAdmin,
    children: [
      {
        title: 'Monitoring',
        path: '',
        pageElement: <UpgradeMonitoring />
      },
      {
        title: 'Monitoring Devices',
        path: 'devices',
        pageElement: <UpgradeMonitoringDevices />
      }
    ]
  }
]
