import React, { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import axios from 'axios'
import { BasicDrawer, DrawerHeaderOptions } from '../../../components/Drawer'
import { AppThunkDispatch, RootState } from '../../../redux'
import DrawerDeviceReadinessTable from './drawerDeviceReadinessTable'
import MissingDevicesSummary from './missingDevicesSummary'
import { missingDevices, setMissingDevices } from '../../../redux/siteReadiness/slice'

export interface OwnProps {
  openDrawer: boolean
  closeDrawer: () => void
  drawerHeader?: DrawerHeaderOptions
}

function mapStateToProps(state: RootState) {
  return {
    selectedSite: state.siteReadiness.selectedSite,
    missingDevices: state.siteReadiness.missingDevices,
    user: state.user,
    missingDevicesRequestState: state.siteReadiness.missingDevicesRequestState
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetMissingDevices: (...args: Parameters<typeof missingDevices>) => dispatch(missingDevices(...args)),
    dispatchSetMissingDevices: (...args: Parameters<typeof setMissingDevices>) => dispatch(setMissingDevices(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type SiteProps = StateProps & OwnProps & DispatchProps

export function SiteReadinessDrawer({
  openDrawer,
  closeDrawer,
  drawerHeader,
  selectedSite,
  dispatchGetMissingDevices,
  dispatchSetMissingDevices,
  missingDevicesRequestState,
  user
}: SiteProps) {
  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    if (
      user.userId
      && user.sessionId
      && user.dataCenter
      && selectedSite
    ) {
      dispatchSetMissingDevices(null)
      dispatchGetMissingDevices({
        userId: user.userId,
        sessionId: user.sessionId,
        dataCenter: user.dataCenter,
        siteId: selectedSite.siteId,
        abortSignal: cancelToken.token
      })
    }
    return () => {
      cancelToken.cancel()
    }
  }, [selectedSite?.siteId])

  if (missingDevicesRequestState === 'pending') {
    return (
      <BasicDrawer
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        drawerHeader={drawerHeader}
      >
        <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
          <Typography>Loading...</Typography>
        </Stack>
      </BasicDrawer>
    )
  }

  return (
    <BasicDrawer
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      drawerHeader={drawerHeader}
    >
      <Stack spacing={2} paddingLeft={2} paddingTop={0} paddingRight={2}>
        <Typography variant="h6">{selectedSite?.siteName}</Typography>

        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Site ID
        </Typography>
        <Stack paddingLeft={1}>
          <Typography variant="body1">{selectedSite?.siteId}</Typography>
        </Stack>

        <MissingDevicesSummary />

        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Current Versions
        </Typography>
        <Stack paddingLeft={1}>
          {selectedSite?.currentVersions?.map((versions) => (
            <Typography variant="body1" key={versions.productName}>
              {`• ${versions.productName} (${versions.productVersion}) `}
            </Typography>
          ))}
        </Stack>

        <Stack>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            Machines
          </Typography>
          <DrawerDeviceReadinessTable />
        </Stack>
      </Stack>
    </BasicDrawer>
  )
}

SiteReadinessDrawer.defaultProps = {
  drawerHeader: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteReadinessDrawer)
