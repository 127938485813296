import { createAsyncThunk } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import { buildAuthenticatedUser } from '../../common/authenticatedUser'
import { getDeviceUpgradeMonitoring, getMonitoringPackageVersions, getSiteUpgradeMonitoring } from '../../data/upgradeMonitoring'
import { SiteMonitoring } from '../../models/upgradeMonitoring/upgradeMonitoring'
import { PackageVersionsResponse } from '../../models/upgradeMonitoring/packageVersions'
import { DeviceMonitoring } from '../../models/upgradeMonitoring/deviceMonitoring'

export const siteUpgradeMonitoring = createAsyncThunk(
  'upgradeMonitoring/siteUpgradeMonitoring',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    companyid: string | undefined
  }): Promise<SiteMonitoring[]> => {
    const {
      userId,
      sessionId,
      dataCenter,
      companyid
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (companyid == null) {
      throw new Error('Upgrade Monitoring requires a company id')
    }
    return getSiteUpgradeMonitoring(authUser, companyid)
  }
)

export const siteMonitoringPackageVersions = createAsyncThunk(
  'upgradeMonitoring/siteMonitoringPackageVersions',
  async (args: {
    userId: string,
    sessionId: string,
    dataCenter: string,
    siteId: string,
    abortSignal: CancelToken
  }): Promise<PackageVersionsResponse> => {
    const {
      userId,
      sessionId,
      dataCenter,
      siteId,
      abortSignal
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (siteId == null) {
      throw new Error('Package Versions requires a site ID')
    }
    return getMonitoringPackageVersions(abortSignal, authUser, siteId)
  }
)

export const deviceUpgradeMonitoring = createAsyncThunk(
  'upgradeMonitoring/deviceUpgradeMonitoring',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    siteId: string
  }): Promise<DeviceMonitoring[]> => {
    const {
      userId,
      sessionId,
      dataCenter,
      siteId
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (siteId == null) {
      throw new Error('Upgrade Monitoring devices requires a site id')
    }
    return getDeviceUpgradeMonitoring(authUser, siteId)
  }
)
