import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SxProps, Theme } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { DefaultDrawerWidth } from '../../common/constants/sizeConstants'

export interface ButtonOptions {
  sx?: SxProps<Theme> | undefined;
  /** The label of this button */
  label: string;
  /** What to execute when this button option is clicked */
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLLIElement>;
  /** The kind of button this should be */
  variant: 'text' | 'contained' | 'outlined';
}

export interface DrawerHeaderOptions {
  /** Enables the close arrow in upper left part of drawer to close the drawer */
  enableCloseArrow: boolean;
  /** Buttons that will show up in the more options drop down menu */
  moreOptions?: ButtonOptions[];
  /** Buttons that will show up in the drawer header */
  buttons?: ButtonOptions[];
}

export interface BasicDrawerProps {
  /** The display width of the drawer */
  width?: number;
  /** This is to set the drawer to open */
  openDrawer: boolean;
  /** This is to set the drawer to close */
  closeDrawer: () => void;
  /** Defines what buttons to display at the top of the drawer and their functionality */
  drawerHeader?: DrawerHeaderOptions;
  /** All the stuff that should be displayed inside a drawer */
  children: React.ReactNode;
}

export function BasicDrawer({
  width,
  openDrawer,
  closeDrawer,
  drawerHeader,
  children
}: BasicDrawerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const headerButtons = drawerHeader?.buttons
  const moreButtonOptions = drawerHeader?.moreOptions
  const drawerWidth = width ?? 600

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      variant="persistent"
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
    >
      <Stack direction="row" width="100%" paddingX={2} paddingY="13px">
        {drawerHeader && drawerHeader.enableCloseArrow && (
          <Button
            onClick={closeDrawer}
            startIcon={<ArrowBack />}
            sx={{
              flex: 1,
              justifyContent: 'flex-start'
            }}
          >
            Close
          </Button>
        )}
        <Stack direction="row" justifyContent="flex-end" flex={3} spacing={1}>
          {moreButtonOptions && moreButtonOptions?.length > 0 && (
            <Button
              id="drawer-more-options-button"
              onClick={handleClick}
              startIcon={<MoreHorizIcon />}
              data-testid="drawer-more-options-button"
            >
              <Menu
                id="drawer-more-options-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'drawer-more-options-button'
                }}
              >
                {moreButtonOptions.map((extraButtonOption) => (
                  <MenuItem
                    key={extraButtonOption.label}
                    onClick={(...args) => {
                      extraButtonOption.onClick(...args)
                      handleClose(...args)
                    }}
                    data-testid={`menu-button-${extraButtonOption.label}`}
                    sx={{ ...extraButtonOption.sx }}
                  >
                    {extraButtonOption.label}
                  </MenuItem>
                ))}
              </Menu>
            </Button>
          )}
          {headerButtons
            && headerButtons.map((buttonOption) => (
              <Button
                key={buttonOption.label}
                variant={buttonOption.variant}
                onClick={(...args) => {
                  buttonOption.onClick(...args)
                }}
                data-testid={`drawer-button-${buttonOption.label}`}
              >
                {buttonOption.label}
              </Button>
            ))}
        </Stack>
      </Stack>
      <Divider />
      {children}
    </Drawer>
  )
}

BasicDrawer.defaultProps = {
  drawerHeader: undefined,
  width: DefaultDrawerWidth
}
