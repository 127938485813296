import { CancelToken } from 'axios'
import { get } from '../common/upgradesApiClient'
import { AuthenticatedUser } from '../common/authenticatedUser'
import { SiteMonitoring } from '../models/upgradeMonitoring/upgradeMonitoring'
import { AppError } from '../common/result'
import { PackageVersionsResponse } from '../models/upgradeMonitoring/packageVersions'
import { DeviceMonitoring } from '../models/upgradeMonitoring/deviceMonitoring'

export async function getSiteUpgradeMonitoring(authUser: AuthenticatedUser, companyId: string): Promise<SiteMonitoring[]> {
  const response = await get<SiteMonitoring[]>(
    {
      route: `monitoring/companysites/${companyId}`,
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

export async function getMonitoringPackageVersions(cancelToken: CancelToken, authUser: AuthenticatedUser, siteId: string): Promise<PackageVersionsResponse> {
  const response = await get<PackageVersionsResponse>(
    {
      route: `monitoring/packageversions/${siteId}`,
      user: authUser,
      cancelToken
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

export async function getDeviceUpgradeMonitoring(authUser: AuthenticatedUser, siteId: string): Promise<DeviceMonitoring[]> {
  const response = await get<DeviceMonitoring[]>(
    {
      route: `monitoring/devices/${siteId}`,
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}
