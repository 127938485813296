import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { AppThunkDispatch, RootState } from '../../../redux'
import { Table } from '../../../components/Table'
import { SiteData } from '../../../data/siteReadinessData'
import { siteReadiness } from '../../../redux/siteReadiness/slice'
import { GreenSuccess, RedError } from '../../../common/constants/colorConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetSites: (...args: Parameters<typeof siteReadiness>) => dispatch(siteReadiness(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    siteReadinessSites: state.siteReadiness.sites,
    siteReadinessRequestState: state.siteReadiness.siteReadinessRequestState
  }
}

export interface OwnProps {
  onRowClick: (site: GridRowParams) => void
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type SiteReadinessTableProps = DispatchProps & StateProps & OwnProps

const checkIcon = <CheckCircleIcon sx={{ color: GreenSuccess }} />// color="success" />
const cancelIcon = <CancelIcon sx={{ color: RedError }} />// color="error" />

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Site ID',
    flex: 1
  },
  {
    field: 'siteName',
    headerName: 'Site Name',
    flex: 1
  },
  {
    field: 'posVersion',
    headerName: 'POS Version',
    flex: 1
  },
  {
    field: 'currentVersions',
    headerName: 'Current Versions',
    flex: 1,
    hide: true
  },
  {
    field: 'isPrereqReady',
    headerName: 'HW/SW Prereq',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (params.row.isPrereqReady ? checkIcon : cancelIcon)
  },
  {
    field: 'isUpgradeReady',
    headerName: 'Upgrade Ready',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (params.row.isUpgradeReady ? checkIcon : cancelIcon)
  }
]

export function SiteReadinessTable(
  {
    siteReadinessRequestState,
    dispatchGetSites,
    user,
    company,
    siteReadinessSites,
    onRowClick
  }: SiteReadinessTableProps
) {
  useEffect(() => {
    if (
      user.dataCenter
      && user.userId
      && user.sessionId
    ) {
      const { userId, sessionId, dataCenter } = user
      dispatchGetSites({
        userId,
        sessionId,
        dataCenter,
        companyid: company.selectedCompany?.id
      })
    }
  }, [company.selectedCompany?.id])

  const rows = siteReadinessSites.map((d: SiteData) => ({
    id: d.siteId,
    siteName: d.siteName,
    currentVersions: d.currentVersions,
    posVersion: d.currentVersions.find((v) => v.productName === 'POS')?.productVersion,
    isPrereqReady: d.isPrereqReady,
    isUpgradeReady: d.isUpgradeReady
  }))

  return (
    <Table
      width="100%"
      rows={siteReadinessRequestState === 'pending' ? [] : rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      loading={siteReadinessRequestState === 'pending'}
      disableSelectionOnClick
      onRowClick={(params) => onRowClick(params)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteReadinessTable)
