import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { AppThunkDispatch, RootState } from '../../redux'
import { BreadcrumbNavigation, BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { setUpgradeMonitoringBreadcrumbs } from '../../redux/upgradeMonitoring/slice'
import DeviceMonitoringTable from './components/deviceMonitoringTable'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetUpgradeMonitoringBreadcrumbs: (...args: Parameters<typeof setUpgradeMonitoringBreadcrumbs>) => dispatch(setUpgradeMonitoringBreadcrumbs(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    breadcrumbs: state.upgradeMonitoring.breadcrumbs,
    selectedSite: state.upgradeMonitoring.selectedSite
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type UpgradeMonitoringDevicesProps = StateProps & DispatchProps

export function UpgradeMonitoringDevices({
  breadcrumbs,
  dispatchSetUpgradeMonitoringBreadcrumbs,
  user,
  selectedSite
}: UpgradeMonitoringDevicesProps): JSX.Element {
  const [breadcrumbDepth] = useState<number>(2)

  const currentSegment: BreadcrumbSegment = {
    name: 'Devices',
    path: '/monitoring/devices',
    order: breadcrumbDepth,
    testId: 'DevicesMonitoring'
  }

  useEffect(() => {
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetUpgradeMonitoringBreadcrumbs(segments) }
    )
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>
  if (selectedSite == null) return <Navigate to="/monitoring" replace />

  return (
    <Stack
      alignItems="baseline"
      spacing={4}
    >
      <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
      <Typography variant="h4" data-testid="UpgradeMonitoringDevicesHeader">
        { `${selectedSite.siteName} Devices` }
      </Typography>
      <DeviceMonitoringTable />
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMonitoringDevices)
