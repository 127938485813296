import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import axios from 'axios'
import { AppThunkDispatch, RootState } from '../../../redux'
import { BasicDrawer, DrawerHeaderOptions } from '../../../components/Drawer'
import { siteMonitoringPackageVersions } from '../../../redux/upgradeMonitoring/reducers'
import SiteMonitoringDrawerTable from './siteMonitoringDrawerTable'
import { RedError } from '../../../common/constants/colorConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetPackageVersions: (...args: Parameters<typeof siteMonitoringPackageVersions>) => dispatch(siteMonitoringPackageVersions(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    selectedSite: state.upgradeMonitoring.selectedSite,
    packageVersions: state.upgradeMonitoring.packageVersions
  }
}

export interface OwnProps {
  openDrawer: boolean
  closeDrawer: () => void
  drawerHeader?: DrawerHeaderOptions
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type SiteMonitoringDrawerProps = DispatchProps & StateProps & OwnProps

export function SiteMonitoringDrawer(
  {
    openDrawer,
    closeDrawer,
    drawerHeader,
    user,
    selectedSite,
    packageVersions,
    dispatchGetPackageVersions
  }: SiteMonitoringDrawerProps
): JSX.Element {
  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    if (
      user.userId
      && user.sessionId
      && user.dataCenter
      && selectedSite?.siteId
    ) {
      const { userId, sessionId, dataCenter } = user
      dispatchGetPackageVersions({
        userId,
        sessionId,
        dataCenter,
        siteId: selectedSite.siteId,
        abortSignal: cancelToken.token
      })
    }

    return () => {
      cancelToken.cancel()
    }
  }, [selectedSite?.siteId])

  return (
    <BasicDrawer
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      drawerHeader={drawerHeader}
    >
      <Stack spacing={2} paddingLeft={2} paddingTop={0} paddingRight={2}>
        {
          selectedSite?.siteName && (
          <Typography variant="h6">{selectedSite.siteName}</Typography>
          )
        }

        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Site ID
        </Typography>
        {
          selectedSite?.siteId && (
          <Stack paddingLeft={1}>
            <Typography variant="body1">{selectedSite.siteId}</Typography>
          </Stack>
          )
        }

        {
          packageVersions?.isPackageJsonValid != null
          && !packageVersions.isPackageJsonValid
          && (
            <Typography color={RedError}>
              Your package.json file at this site is either formatted incorrectly or you are pointing to an incorrect product version.
            </Typography>
          )
        }

        <SiteMonitoringDrawerTable />
      </Stack>
    </BasicDrawer>
  )
}

SiteMonitoringDrawer.defaultProps = {
  drawerHeader: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMonitoringDrawer)
