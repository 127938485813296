import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { AppThunkDispatch, RootState } from '../../redux'
import { BreadcrumbNavigation, BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { setRemovePackageVersions, setSelectedSite, setUpgradeMonitoringBreadcrumbs } from '../../redux/upgradeMonitoring/slice'
import UpgradeMonitoringTable from './components/upgradeMonitoringTable'
import SiteMonitoringDrawer from './components/siteMonitoringDrawer'
import { DrawerHeaderOptions } from '../../components/Drawer'
import { DefaultDrawerWidth } from '../../common/constants/sizeConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetUpgradeMonitoringBreadcrumbs: (...args: Parameters<typeof setUpgradeMonitoringBreadcrumbs>) => dispatch(setUpgradeMonitoringBreadcrumbs(...args)),
    dispatchSetSelectedSite: (...args: Parameters<typeof setSelectedSite>) => dispatch(setSelectedSite(...args)),
    dispatchSetRemovePackageVersions: (...args: Parameters<typeof setRemovePackageVersions>) => dispatch(setRemovePackageVersions(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    breadcrumbs: state.upgradeMonitoring.breadcrumbs,
    user: state.user,
    selectedSite: state.upgradeMonitoring.selectedSite
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type UpgradeMonitoringProps = StateProps & DispatchProps

export function UpgradeMonitoring({
  breadcrumbs,
  user,
  selectedSite,
  dispatchSetUpgradeMonitoringBreadcrumbs,
  dispatchSetSelectedSite,
  dispatchSetRemovePackageVersions
}: UpgradeMonitoringProps): JSX.Element {
  const [breadcrumbDepth] = useState<number>(1)

  const currentSegment: BreadcrumbSegment = {
    name: 'Monitoring',
    path: '/monitoring',
    order: breadcrumbDepth,
    testId: 'Monitoring'
  }

  useEffect(() => {
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetUpgradeMonitoringBreadcrumbs(segments) }
    )
    dispatchSetSelectedSite(undefined)
    dispatchSetRemovePackageVersions(undefined)
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>

  const drawerHeaderOptions: DrawerHeaderOptions = {
    enableCloseArrow: true,
    buttons: []
  }

  const handleDrawerClose = () => {
    dispatchSetSelectedSite(undefined)
    dispatchSetRemovePackageVersions(undefined)
  }

  return (
    <>
      <Stack
        alignItems="baseline"
        spacing={4}
        sx={{
          width:
        selectedSite != null ? `calc(100% - ${DefaultDrawerWidth}px)` : '100%'
        }}
      >
        <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
        <Typography variant="h4" data-testid="UpgradeMonitoringHeader">
          Monitoring
        </Typography>
        <Typography variant="body1">
          Click any site to view detail/devices
        </Typography>
        <Typography>
          If you do not see your site on this grid, please verify Upgrade Agent is installed on all devices and is reporting data through Pulse Streaming (BOH) and Edge Agent (FOH)
        </Typography>
        <UpgradeMonitoringTable />
      </Stack>
      <SiteMonitoringDrawer
        openDrawer={selectedSite != null}
        closeDrawer={handleDrawerClose}
        drawerHeader={drawerHeaderOptions}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMonitoring)
